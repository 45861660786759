import React from 'react'


export default ({item, show, backgroundUplift}) => {
  const mainBackgroundStyle = {
    backgroundImage: `url("${item.imagen.localFile.publicURL}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }

  if (backgroundUplift) {
    mainBackgroundStyle.backgroundPosition = 'center -35px'
  }

  return (
    <section style={{display: show ? 'block' : 'none'}}>
      <section className={`content-container ${show ? 'zoomIn' : ''}`} style={mainBackgroundStyle}/>
      <section
        className={`footer-container flex-set flex--column flex--align-items-start flex--content-center ${show ? 'slideInUp' : ''}`}>

        {item.antetitulo  &&
        <span className={`footer-subtitle footer-text-with-margin text-uppercase ${show ? 'fadeIn' : ''}`}>{item.antetitulo}</span>
        }

        <span className={`footer-title ${show ? 'fadeIn' : ''} ${item.antetitulo || (!item.subtitulo1 && !item.subtitulo2) ? '' : 'footer-text-with-margin'}`}>{item.titulo}</span>

        {item.subtitulo1 && item.subtitulo1.split("<br/>").map((subtituloSplited) =>
          <span className={`footer-subtitle text-uppercase ${show ? 'fadeIn' : ''}`}>{subtituloSplited}</span>
        )
        }

        {item.subtitulo2  &&
          <span className={`footer-subtitle text-uppercase ${show ? 'fadeIn' : ''}`}>{item.subtitulo2}</span>
        }

      </section>
    </section>
  )
}
