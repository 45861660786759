import * as React from "react"
import {useEffect, useRef, useState} from "react"

import Content from "../components/content"

import '../styles/global.scss'
import IconoFarmacia from '../images/icon-farmacia.png'
import VideoIntro from '../videos/farmacia-intro.mp4'
import VideoOutro from '../videos/farmacia-outro.mp4'


const FarmaciasPage = ({ data }) => {

  const [started, setStarted] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);
  const [displayedEvent, setDisplayedEvent] = useState(null);
  const [outroVideo, setOutroVideo] = useState(false);

  const startedRef = useRef(started);
  const displayedEventRef = useRef(displayedEvent);
  const videoIntroRef = useRef(null);
  const videoOutroRef = useRef(null);

  startedRef.current = started;
  displayedEventRef.current = displayedEvent;

  const introEnd = () => {
    setIntroVideo(false)
    setDisplayedEvent(0)
  }

  const BroadSignPlay = () => {
    if (!started) {
      videoIntroRef.current.play();
    }
  }

  useEffect(() => {
    if (!startedRef.current) {
      setInterval(() => {
        if (window.startGo && !startedRef.current) {
          window.startGo = false;
          setStarted(true);
          BroadSignPlay();
        }
      }, 500)
    }
  }, [started])

  const farmacias = data.farmacias.edges.map((edge) => {
    const farmaciaId = edge.node.uid;
    const imagen = data.backgrounds.edges.filter((background) => background.node.farmacia === farmaciaId)[0].node.imagen

    return {
      titulo: edge.node.titulo,
      subtitulo1: edge.node.localizacion,
      subtitulo2: edge.node.telefono,
      antetitulo: edge.node.horario,
      imagen
    }
  });

  useEffect(() => {
    let interval;
    if (!introVideo && !outroVideo) {
      interval = setInterval(() => {
        let currDisplayedEvent = displayedEventRef.current;
        //console.log('transition', currDisplayedEvent, noticias.length - 1, currDisplayedEvent + 1 < noticias.length - 1 )
        if (currDisplayedEvent + 1 < farmacias.length) {
          setDisplayedEvent(currDisplayedEvent => currDisplayedEvent + 1)
        } else {
          setOutroVideo(true)
          setDisplayedEvent(null)
          videoOutroRef.current.play();
        }
      }, 12000 / farmacias.length)
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [introVideo, outroVideo]);

  return (
    <main className="main farmacias">
      <video ref={videoIntroRef} style={{opacity: introVideo ? 1 : 0}} src={VideoIntro} autoPlay={false} controls={false} muted={true} onEnded={() => {introEnd()}}/>

      <section className={`header-container flex-set flex--row flex--align-items-center flex--content-center ${introVideo ? '' : 'slideInDown'}`}>
        <img src={IconoFarmacia} className="header-icon" alt=""/>
        <span className="header-text text-uppercase">Guardiako Farmazia</span>
      </section>

      {farmacias.map((farmacia, index) =>
        <Content item={farmacia} show={displayedEventRef.current === index && !introVideo && !outroVideo} key={index}/>
      )}

      <video ref={videoOutroRef} style={{opacity: outroVideo ? 1 : 0}} src={VideoOutro} autoPlay={false} controls={false} muted={true}/>

    </main>
  )
}

export const pageQuery = graphql`  
  {
    farmacias: allStrapiFarmacias {
      edges {
        node {
          uid,
          titulo,
          localizacion,
          telefono,
          horario
        }
      }
    }
    backgrounds: allStrapiFarmaciasBackgrounds {
      edges {
        node {
          imagen {
            localFile {
              publicURL
            }
          }
          farmacia
        }
      }
    }
  }
`

export default FarmaciasPage
